


























































import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { FETCH_ASSETS, GET_ASSETS } from '../../../store';
import { Asset, ListOfAssets } from '../../../types';
import { ApiResponse } from '@/components/types';
import { SearchableMixin } from '@/views/mixin';
import NewAssetDialog from '../dialogs/NewAssetDialog.vue';
import UpdateAssetDialog from '../dialogs/UpdateAssetDialog.vue';
import RemoveAssetDialog from '../dialogs/RemoveAssetDialog.vue';
import CardRow from '@/components/CardRow.vue';
import AppTable from '@/components/AppTable.vue';

const ListOfAssets = namespace('listOfAssets');

const ListSearchDialog = () => import('../../ListSearchDialog.vue');

@Component({
  components: { AppTable, RemoveAssetDialog, UpdateAssetDialog, NewAssetDialog, ListSearchDialog, CardRow }
})
export default class AssetsCard extends SearchableMixin {
  @Prop({ type: Object, required: true }) listOfAssets!: ListOfAssets;

  @ListOfAssets.Action(FETCH_ASSETS) fetchAssets!: (listOfAssetsId: string) => Promise<ApiResponse>;
  @ListOfAssets.Getter(GET_ASSETS) getAssets!: (listOfAssetsId: string) => Asset[];

  removeAsset: Asset | null = null;
  updateAsset: Asset | null = null;

  pagination = { sortBy: 'groupTrans', descending: false, rowsPerPage: -1 };

  get assets() {
    return this.getAssets(this.listOfAssets.id).map((asset) => ({
      ...asset,
      transactedTrans: format(asset.transacted, 'dd.MM.yyyy'),
      groupTrans: this.$i18n.t(`listOfAssets.list.${asset.group}`)
    }));
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.transacted'), value: 'transacted', sortable: true },
      { align: 'left', text: this.$i18n.t('listOfAssets.title'), value: 'groupTrans', sortable: true },
      { align: 'left', text: this.$i18n.t('common.note'), value: 'note', sortable: true },
      { align: 'right', text: this.$i18n.t('transaction.deposit'), value: 'balance', sortable: false },
      { align: 'right', text: this.$i18n.t('transaction.withdraw'), value: 'created', sortable: false },
      { align: 'right', text: '', value: 'transactedTrans', sortable: false }
    ];
  }

  itemClasses(asset: Asset) {
    if (asset.balance < 0) {
      return 'red--text lighten-4';
    }

    return '';
  }

  created() {
    return this.fetchAssets(this.listOfAssets.id);
  }
}
