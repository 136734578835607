













import { Component, Prop, Vue } from 'vue-property-decorator';
import { AssetGroup } from '../types';
import { TranslateResult } from 'vue-i18n';

@Component({})
export default class ListOfAssetsGroups extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;

  get list(): Array<{ value: AssetGroup, text: TranslateResult }> {
    return [
      { value: 'property', text: this.$i18n.t('listOfAssets.list.property') },
      { value: 'commercial_transaction', text: this.$i18n.t('listOfAssets.list.commercial_transaction') },
      { value: 'cash', text: this.$i18n.t('listOfAssets.list.cash') },
      { value: 'pending_claims', text: this.$i18n.t('listOfAssets.list.pending_claims') },
      { value: 'household_appliance', text: this.$i18n.t('listOfAssets.list.household_appliance') },
      { value: 'artifact', text: this.$i18n.t('listOfAssets.list.artifact') },
      { value: 'personal_items', text: this.$i18n.t('listOfAssets.list.personal_items') },
      { value: 'appliances', text: this.$i18n.t('listOfAssets.list.appliances') },
      { value: 'vehicles', text: this.$i18n.t('listOfAssets.list.vehicles') },
      { value: 'sharing_community_of_heirs', text: this.$i18n.t('listOfAssets.list.sharing_community_of_heirs') },
      { value: 'sharing_things_and_rights', text: this.$i18n.t('listOfAssets.list.sharing_things_and_rights') },
      { value: 'other_assets', text: this.$i18n.t('listOfAssets.list.other_assets') },
      { value: 'liabilities', text: this.$i18n.t('listOfAssets.list.liabilities') },
      { value: 'other_accounts_payable', text: this.$i18n.t('listOfAssets.list.other_accounts_payable') },
      { value: 'earned_income', text: this.$i18n.t('listOfAssets.list.earned_income') },
      { value: 'bonds', text: this.$i18n.t('listOfAssets.list.bonds') },
      { value: 'welfare', text: this.$i18n.t('listOfAssets.list.welfare') },
      { value: 'sick_benefit', text: this.$i18n.t('listOfAssets.list.sick_benefit') },
      { value: 'income', text: this.$i18n.t('listOfAssets.list.income') }
    ];
  }

  created() {
    this.$emit('input', this.value);
  }
}
