






































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CLOSE } from '../../../store';
import DatePicker from '@/components/form/DatePicker.vue';
import { CloseListOfAssets, ListOfAssets } from '../../../types';
import { ApiResponse } from '@/components/types';
import format from '@/filter/dateFormat';

const ListOfAssets = namespace('listOfAssets');

@Component({
  components: {
    DatePicker
  }
})
export default class ClosedDialog extends Vue {
  @Prop({ type: Object, required: true }) listOfAssets!: ListOfAssets;

  @ListOfAssets.Action(CLOSE) closeListOfAssets!: (params: CloseListOfAssets) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  value: CloseListOfAssets = {
    list_of_assets_id: this.listOfAssets.id,
    closed: this.listOfAssets.closed || format(Date.now(), 'yyyy-MM-dd')
  };

  @Watch('dialog', { immediate: true })
  onDialogChange() {
    setTimeout(() => {
      this.error = null;
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  save() {
    this.error = null;

    return this.closeListOfAssets(this.value);
  }
}
