

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DatePicker from '@/components/form/DatePicker.vue';
import { ListOfAssets, WriteAsset } from '../../../types';
import { CREATE_ASSET } from '../../../store';
import { ApiResponse } from '@/components/types';
import { createAsset } from '@/modules/listOfAssets/model';
import ListOfAssetsGroups from '@/modules/listOfAssets/components/ListOfAssetsGroups.vue';

const ListOfAssets = namespace('listOfAssets');

@Component({
  components: {
    ListOfAssetsGroups,
    DatePicker
  }
})
export default class NewAssetDialog extends Vue {
  @Prop({ type: Object, required: true }) listOfAssets!: ListOfAssets;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;

  @ListOfAssets.Action(CREATE_ASSET) create!: (asset: WriteAsset) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value = createAsset(this.listOfAssets.id);

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.error = null;
      this.value = createAsset(this.listOfAssets.id);
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  async save() {
    this.error = null;

    return this.create(this.value);
  }
}
