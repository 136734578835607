













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { Asset, WriteAsset } from '../../../types';
import { REMOVE_ASSET } from '../../../store';
import { MapAssetToWriteAsset } from '../../../model';
import ErrorAlert from '@/components/ErrorAlert.vue';
import CardRow from '@/components/CardRow.vue';
import AsyncDeleteButton from '@/components/form/AsyncDeleteButton.vue';

const ListOfAssets = namespace('listOfAssets');

@Component({
  components: {
    AsyncDeleteButton,
    ErrorAlert,
    CardRow
  }
})
export default class RemoveAssetDialog extends Vue {
  @Prop({ type: Object }) asset!: Asset | null;

  @ListOfAssets.Action(REMOVE_ASSET) removeAsset!: (asset: WriteAsset) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  value: WriteAsset | null = null;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      this.$emit('update:asset', null);
    }, 200);
  }

  @Watch('asset')
  watchAsset(asset: Asset) {
    if (!asset) return;

    this.value = MapAssetToWriteAsset(asset);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    if (!this.value) return;

    this.error = null;

    return this.removeAsset(this.value);
  }
}
