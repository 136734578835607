























import { Component, Prop, Vue } from 'vue-property-decorator';
import { ListOfAssets } from '@/modules/listOfAssets/types';
import ListOfAssetsTitleCard from '../cards/ListOfAssetsTitleCard.vue';
import GroupCard from '../cards/GroupCard.vue';
import AssetsCard from '../cards/AssetsCard.vue';

@Component({
  components: { AssetsCard, GroupCard, ListOfAssetsTitleCard }
})
export default class AssetsTab extends Vue {
  @Prop({ type: Object }) listOfAssets?: ListOfAssets;
}
