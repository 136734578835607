















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DatePicker from '@/components/form/DatePicker.vue';
import ListOfAssetsGroups from '../../ListOfAssetsGroups.vue';
import { UPDATE_ASSET } from '../../../store';
import { Asset, WriteAsset } from '../../../types';
import { ApiResponse } from '@/components/types';
import { MapAssetToWriteAsset } from '@/modules/listOfAssets/model';

const ListOfAssets = namespace('listOfAssets');

@Component({
  components: {
    ListOfAssetsGroups,
    DatePicker
  }
})
export default class UpdateAssetDialog extends Vue {
  @Prop({ type: Object }) asset!: Asset | null;

  @ListOfAssets.Action(UPDATE_ASSET) updateAsset!: (asset: WriteAsset) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  value: WriteAsset | null = null;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      this.$emit('update:asset', null);
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  @Watch('asset')
  watchAsset(asset: Asset) {
    if (!asset) return;

    this.value = MapAssetToWriteAsset(asset);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    if (!this.value) return;

    this.error = null;

    return this.updateAsset(this.value);
  }
}
