






































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { UPDATE_OPENED } from '../../../store';
import DatePicker from '@/components/form/DatePicker.vue';
import { ChangeOpen, ListOfAssets } from '../../../types';
import { ApiResponse } from '@/components/types';

const ListOfAssets = namespace('listOfAssets');

@Component({
  components: {
    DatePicker
  }
})
export default class OpenedDialog extends Vue {
  @Prop({ type: Object, required: true }) listOfAssets!: ListOfAssets;

  @ListOfAssets.Action(UPDATE_OPENED) updateOpened!: (params: ChangeOpen) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  value: ChangeOpen = {
    list_of_assets_id: this.listOfAssets.id,
    opened: this.listOfAssets.opened
  };

  @Watch('dialog', { immediate: true })
  onDialogChange() {
    setTimeout(() => {
      this.error = null;
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  save() {
    this.error = null;

    return this.updateOpened(this.value);
  }
}
