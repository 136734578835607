






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import CardRow from '@/components/CardRow.vue';
import { ListOfAssets } from '../../../types';
import format from '@/filter/dateFormat';
import OpenedDialog from '@/modules/listOfAssets/components/details/dialogs/OpenedDialog.vue';
import ClosedDialog from '@/modules/listOfAssets/components/details/dialogs/ClosedDialog.vue';
import StartAssetRegisterDownloadAction from '@/modules/listOfAssets/components/StartAssetRegisterDownloadAction.vue';
import FinalInvoiceDownloadAction from '@/modules/listOfAssets/components/FinalInvoiceDownloadAction.vue';

const StartAssetDialog = () => import('@/modules/listOfAssets/components/details/dialogs/StartAssetDialog.vue');

@Component({
  components: {
    StartAssetDialog,
    StartAssetRegisterDownloadAction,
    FinalInvoiceDownloadAction,
    ClosedDialog,
    OpenedDialog,
    CardRow
  }
})
export default class ListOfAssetsTitleCard extends Vue {
  @Prop({ type: Object }) listOfAssets!: ListOfAssets;

  get closed() {
    if (!this.listOfAssets.closed) {
      return '';
    }

    return format(this.listOfAssets.closed, 'dd.MM.yyyy');
  }
}
