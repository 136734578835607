






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CardRow from '@/components/CardRow.vue';
import { GET_ASSETS } from '../../../store';
import { Asset, FinanceAccount, GroupStatus, ListOfAssets } from '../../../types';
import ErrorAlert from '@/components/ErrorAlert.vue';
import { GET_BANK_ACCOUNTS } from '@/modules/listOfAssets/store/bankAccount';
import { GET_CASH_ACCOUNTS } from '@/modules/listOfAssets/store/cashAccount';
import { GET_CREDIT_ACCOUNTS } from '@/modules/listOfAssets/store/creditAccount';

const ListOfAssets = namespace('listOfAssets');

const BankAccount = namespace('listOfAssets/bankAccount');
const CashAccount = namespace('listOfAssets/cashAccount');
const CreditAccount = namespace('listOfAssets/creditAccount');

@Component({
  components: { ErrorAlert, CardRow }
})
export default class ListOfAssetsCard extends Vue {
  @Prop({ type: Object, required: true }) listOfAssets!: ListOfAssets;

  @ListOfAssets.Getter(GET_ASSETS) getAsses!: (listOfAssetsId: string) => Asset[];

  @BankAccount.Getter(GET_BANK_ACCOUNTS) getBankAccounts!: (listOfAssetsId: string) => FinanceAccount[];
  @CashAccount.Getter(GET_CASH_ACCOUNTS) getCashAccounts!: (listOfAssetsId: string) => FinanceAccount[];
  @CreditAccount.Getter(GET_CREDIT_ACCOUNTS) getCreditAccounts!: (listOfAssetsId: string) => FinanceAccount[];

  error: any = null;

  get groups() {
    const groups: GroupStatus = {
      I: { category: 'I', balance: 0 },
      II: { category: 'II', balance: 0 },
      III: { category: 'III', balance: 0 }
    };

    [
      ...this.getBankAccounts(this.listOfAssets.id),
      ...this.getCashAccounts(this.listOfAssets.id),
      ...this.getCreditAccounts(this.listOfAssets.id)
    ].forEach((financeAccount: FinanceAccount): void => {
      if (financeAccount.type === 'accounts_payable') {
        groups.II.balance += financeAccount.balance
        return
      }

      groups.I.balance += financeAccount.balance;
    });

    return this.getAsses(this.listOfAssets.id).reduce((result: GroupStatus, asset: Asset): GroupStatus => {
      groups[asset.category].balance += asset.balance;

      return groups;
    }, groups);
  }
}
